// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.required {
	color: red;
}
.inputclass{
	float: left;
	box-sizing: border-box;
	padding: 0.5em;
	width: 15%;
	margin-left: -15px;
}


.dateinputclass{
	float: left;
	box-sizing: border-box;
	padding: 0.5em;
	width: 15%;
	margin-left: -25px;
}

.labelclass{
	float: left;
	box-sizing: border-box;
	padding-top: 13px;
	width: 10%;
}

.datelabelclass{
	float: right;
	box-sizing: border-box;
	padding-top: 13px;
	width: 7.4%;
}

.todatelabelclass{
	float: right;
	box-sizing: border-box;
	padding-top: 13px;
	width: 6%;
}

.ui-g-2{
	margin-left: -15px;
}`, "",{"version":3,"sources":["webpack://./src/app/supplier-engineer/supplier-engineer.component.css"],"names":[],"mappings":"AAAA;CACC,UAAU;AACX;AACA;CACC,WAAW;CACX,sBAAsB;CACtB,cAAc;CACd,UAAU;CACV,kBAAkB;AACnB;;;AAGA;CACC,WAAW;CACX,sBAAsB;CACtB,cAAc;CACd,UAAU;CACV,kBAAkB;AACnB;;AAEA;CACC,WAAW;CACX,sBAAsB;CACtB,iBAAiB;CACjB,UAAU;AACX;;AAEA;CACC,YAAY;CACZ,sBAAsB;CACtB,iBAAiB;CACjB,WAAW;AACZ;;AAEA;CACC,YAAY;CACZ,sBAAsB;CACtB,iBAAiB;CACjB,SAAS;AACV;;AAEA;CACC,kBAAkB;AACnB","sourcesContent":[".required {\n\tcolor: red;\n}\n.inputclass{\n\tfloat: left;\n\tbox-sizing: border-box;\n\tpadding: 0.5em;\n\twidth: 15%;\n\tmargin-left: -15px;\n}\n\n\n.dateinputclass{\n\tfloat: left;\n\tbox-sizing: border-box;\n\tpadding: 0.5em;\n\twidth: 15%;\n\tmargin-left: -25px;\n}\n\n.labelclass{\n\tfloat: left;\n\tbox-sizing: border-box;\n\tpadding-top: 13px;\n\twidth: 10%;\n}\n\n.datelabelclass{\n\tfloat: right;\n\tbox-sizing: border-box;\n\tpadding-top: 13px;\n\twidth: 7.4%;\n}\n\n.todatelabelclass{\n\tfloat: right;\n\tbox-sizing: border-box;\n\tpadding-top: 13px;\n\twidth: 6%;\n}\n\n.ui-g-2{\n\tmargin-left: -15px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
