/**
 * @author DCHIRUM1
 */
import {Component, OnInit} from '@angular/core';
import {MenuItem, MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {RfiThresholdRequestService} from '../services/rfi/rfi-threshold-request.service';
import {ExcelService} from '../services/excel.service';
import moment from 'moment';

interface FilteredItem {
	id: number;
	name: string;
}

interface FilterEvent {
	filteredValue: FilteredItem[];
}

@Component({
	selector: 'app-threshold',
	templateUrl: './threshold.component.html'
})
export class ThresholdRequestComponent implements OnInit {
	columns = [];
	rfiThresholds = [];
	rfiThresholdsExcel = [];
	selectedThreshold;
	breadCrumItems: MenuItem[];

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private rfiThreasholdService: RfiThresholdRequestService,
		private messageService: MessageService,
		private excelService: ExcelService
	) {}

	ngOnInit() {
		this.retriveThresold();

		this.columns = [
			// {field: 'program', header: 'Program'},
			// {field: 'commodity', header: 'Material Group'},
			// {field: 'commodityDesc', header: 'Material Group Description'},
			{field: 'psa', header: 'PSA'},
			{field: 'mcost', header: 'Piece Price Threshold %'},
			{field: 'tcost', header: 'Tooling Cost Threshold %'},
			{field: 'createdBy', header: 'Created By'},
			{field: 'createDate', header: 'Created Date'},
			{field: 'updateBy', header: 'Updated By'},
			{field: 'updateDate', header: 'Updated Date'}
		];
	}

	onFilter(event: FilterEvent): void {
		if (event.filteredValue) {
			this.rfiThresholdsExcel = event.filteredValue;
		}
	}

	onRowSelect(data) {
		this.router.navigate(['create-threshold/edit/' + data.psa]);
	}

	isPdEngg() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return apsRoles.includes('PD_Engineer');
	}

	retriveThresold() {
		let userId = sessionStorage.getItem('userId');
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (
			apsRoles.includes('eDCM_IT_ADMIN') ||
			apsRoles.includes('eDCM_Super_Buyer') ||
			apsRoles.includes('PD_Engineer')
		) {
			userId = 'eDCM_IT_ADMIN';
		}

		this.rfiThreasholdService.findAll(userId).subscribe((response) => {
			this.rfiThresholds = response;
			this.rfiThresholdsExcel = this.rfiThresholds;
		});

		this.breadCrumItems = [
			{
				label: 'Buyer Home Page',
				url: '#/buyer-supplier/buyer',
				target: '_self'
			},
			{label: 'Manage Threshold', url: '#/threshold', target: '_self'}
		];
	}

	exportAsXLSX(): void {
		const rfiExports = [];

		this.rfiThresholdsExcel.forEach((rfi) => {
			const rfiExport = {};
			rfiExport['PSA'] = rfi.psa;
			rfiExport['Material Cost Threshold %'] = rfi.mcost;
			rfiExport['Tooling Cost Threshold %'] = rfi.tcost;
			rfiExport['Created By'] = rfi.createdBy;
			rfiExport['Created Date'] = rfi.createDate
				? moment(rfi.createDate).format('MM/DD/YYYY HH:MM')
				: rfi.createDate;
			rfiExport['Updated By'] = rfi.updateBy;
			rfiExport['Updated Date'] = rfi.updateDate
				? moment(rfi.updateDate).format('MM/DD/YYYY HH:MM')
				: rfi.updateDate;

			rfiExports.push(rfiExport);
		});

		this.excelService.exportAsExcelFile(rfiExports, 'Thresholds');
	}
}
