/**
 * @author DCHIRUM1
 */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Config} from '../config';

@Injectable({
	providedIn: 'root'
})
export class ApsService {
	public roles: BehaviorSubject<string[]>;
	private config = new Config();
	apsRolesUrl = `${this.config.url}edcmservice/aps/userRoles`;
	private readonly token;

	constructor(private http: HttpClient) {
		this.token = sessionStorage.getItem('encodedAccessToken');
		this.roles = new BehaviorSubject([]);
	}

	getApsUserRoles(): Observable<string[]> {
		return this.http.get<string[]>(this.apsRolesUrl);
	}
}
