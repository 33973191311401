import {Injectable} from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';

/** This class wraps some functionality of the pdfmake package in an easy to use service. See the below example:
 *
 * -Dependency Inject the ExportPDFService into component
 * 	constructor(private exportPDFService: ExportPDFService) {}
 *
 * -Create an array to hold the content of the export
 * 	const tableContent = [];
 *
 * -Push the headings
 * 	tableContent.push([
 * 		{text: 'Booking Confirmation Number', bold: true},
 * 		{text: 'Customer Name', bold: true},
 * 		{text: 'Adventure', bold: true},
 * 		{text: 'Start Date', bold: true},
 * 		{text: 'End Date', bold: true}
 * 	]);
 *
 * -Push the body contents
 * 	bookings.forEach((booking) => {
 * 		tableContent.push([booking.confirmationNumber, booking.customerName, booking.adventureName, booking.startDate, booking.endDate]);
 * 	});
 *
 * -Call the service with the content and desired file name
 * 		this.exportPDFService.exportPDF(tableContent, 'bookings.pdf');
 */
@Injectable({
	providedIn: 'root'
})
export class ExportPDFService {
	/** Creates a new ExportPDFService and links the fonts file from pdfFonts */
	constructor() {
		pdfMake.vfs = pdfFonts.pdfMake.vfs;
	}

	/** exports table data to a PDF file
	 *
	 * @param tableContent is the contents of the table, which is an array of any object
	 * @param fileName is the name for the file that the data will be exported to
	 */
	exportPDF(tableContent: any[], fileName: string) {
		//set the width for each column, this is needed in the doc definition object for pdfMake to function properly
		//const widths: string[] = [];
		// for (const i of tableContent[0]) {
		// 	widths.push('auto');
		// }

		//definition of the pdf file
		const docDefinition = {
			content: [
				{
					table: {
						headerRows: 1,
						//widths,
						body: tableContent
					}
				}
			],
			styles: {
				header: {
					bold: true
				}
			}
		};
		pdfMake.createPdf(docDefinition).download(fileName);
	}
}
