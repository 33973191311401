import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class RFIDocumentService {
	rfiDocumentUrl = `${environment.BACKEND_URL + 'edcmservice'}/rfi-document`;

	constructor(private http: HttpClient) {}

	uploadDocument(formData, prefix, baseNumber): Observable<any> {
		return this.http.post<any>(
			this.rfiDocumentUrl + '/uploadFile/' + prefix + '/' + baseNumber,
			formData
		);
	}

	showDocumentList(prefix, baseNumber): Observable<any> {
		return this.http.get<any>(
			this.rfiDocumentUrl + '/' + prefix + '/' + baseNumber
		);
	}

	deleteDocument(id) {
		return this.http.delete(this.rfiDocumentUrl + '/' + id);
	}

	downloadDocument(fileName) {
		return this.http.get(this.rfiDocumentUrl + '/files/' + fileName, {
			responseType: 'blob'
		});
	}
}
