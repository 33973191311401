/**
 * @author DCHIRUM1
 */
import {Component, OnInit} from '@angular/core';
import {LazyLoadEvent, MenuItem, MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {ExcelService} from '../services/excel.service';
import {SupplierContactService} from '../services/rfi/supplierContact.service';
import moment from 'moment';

interface SupplierEngineer {
	id: string;
	name: string;
	fromDate: string;
	toDate: string;
	isMoving: string;
}

interface FilterEvent {
	filteredValue: SupplierEngineer[];
}

interface SelectAllEvent {
	checked: boolean;
}

@Component({
	selector: 'app-supplier-contact',
	templateUrl: './supplier-contact.component.html',
	styleUrls: ['./supplier-contact.component.css'],
	providers: [MessageService]
})
export class SupplierContactComponent implements OnInit {
	loading = true;
	filterOptions: Record<string, unknown>;
	totalRecords: number;
	minDate: Date | undefined;
	columns = [];
	supplierContacts = [];
	private supplierContactExcel = [];
	private selectedBuddyBuyer;
	breadCrumItems: MenuItem[];
	private dtSelectedRows = [];
	isMoving = 'Moving';
	supplier;
	fromDate;
	toDate;
	private suppContactMap = {};
	isSubmit;

	constructor(
		private router: Router,
		private supplierContactsService: SupplierContactService,
		private messageService: MessageService,
		private excelService: ExcelService
	) {}

	ngOnInit() {
		this.filterOptions = {};
		this.retriveSupplierContact(this.filterOptions);

		this.columns = [
			{field: 'psa', header: 'PSA#'},
			{field: 'program', header: 'Lead Program'},
			{field: 'commodity', header: 'Material Group'},
			{field: 'commodityName', header: 'Material Group Description'},
			{field: 'supplier', header: 'Supplier'},
			{field: 'supplierContact', header: 'Updated Supplier'},
			{field: 'fromDate', header: 'From Date'},
			{field: 'toDate', header: 'To Date'},
			{field: 'updateBy', header: 'Updated By'},
			{field: 'updateDate', header: 'Updated Date'},
			{field: 'status', header: 'Reason'}
		];
		const today = new Date();
		const month = today.getMonth() + 1;
		const year = today.getFullYear();
		const prevMonth = month === 0 ? 11 : month - 1;
		const prevYear = prevMonth === 11 ? year - 1 : year;
		this.minDate = new Date();
		this.minDate.setMonth(prevMonth);
		this.minDate.setFullYear(prevYear);
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		const buyerSupplierUrl =
			this.isBuyerRole() || this.isAdminRole(apsRoles) === 'buyer'
				? 'buyer'
				: 'supplier';

		this.breadCrumItems = [
			{
				label:
					this.isBuyerRole() || this.isAdminRole(apsRoles)
						? 'Buyer Home Page'
						: 'Supplier Home Page',
				url: '#/buyer-supplier/' + buyerSupplierUrl,
				target: '_self'
			},
			{label: 'Update Supplier'}
		];
	}

	onFilter(event: FilterEvent): void {
		if (event.filteredValue) {
			this.supplierContactExcel = event.filteredValue;
		}
	}

	selectAll(event: SelectAllEvent) {
		if (event.checked) {
			this.dtSelectedRows = this.supplierContacts;
		} else {
			this.dtSelectedRows = [];
		}
	}

	onRowSelect(data) {
		this.router.navigate(['create-supplier-contact/edit/' + data.id]);
	}

	addSupplierContact() {
		this.router.navigate(['create-supplier-contact/create/' + null]);
	}

	isAdminRole(apsRoles) {
		return (
			apsRoles.includes('eDCM_IT_ADMIN') ||
			apsRoles.includes('PD_Engineer') ||
			apsRoles.includes('eDCM_Super_Buyer')
		);
	}

	isBuyerRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return apsRoles.includes('Buyer') || apsRoles.includes('JV Buyer');
	}

	retriveSupplierContact(filterOptions, event?: LazyLoadEvent) {
		this.loading = true;
		let page = 0;
		let size = 10;
		let sortColumn = '';
		let sortOrder = 1;

		if (event) {
			page = event.first / event.rows;
			size = event.rows;
			sortColumn = event.sortField;
			sortOrder = event.sortOrder;
		}

		if (event && event.filters) {
			filterOptions = {
				psa: event.filters.psa ? event.filters.psa.value : null,
				program: event.filters.program
					? event.filters.program.value
					: null,
				commodity: event.filters.commodity
					? event.filters.commodity.value
					: null,
				commodityName: event.filters.commodityName
					? event.filters.commodityName.value
					: null,
				supplier: event.filters.supplier
					? event.filters.supplier.value
					: null,
				supplierContact: event.filters.supplierContact
					? event.filters.supplierContact.value
					: null,
				fromDate: event.filters.fromDate
					? event.filters.fromDate.value
					: null,
				toDate: event.filters.toDate
					? event.filters.toDate.value
					: null,
				updateBy: event.filters.updateBy
					? event.filters.updateBy.value
					: null,
				updateDate: event.filters.updateDate
					? event.filters.updateDate.value
					: null,
				status: event.filters.status ? event.filters.status.value : null
			};
		}

		let userId = sessionStorage.getItem('emailAddress');
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (this.isAdminRole(apsRoles)) {
			userId = 'eDCM_IT_ADMIN';
		}

		const isBuyer = this.isBuyerRole();
		if (isBuyer) {
			userId = sessionStorage.getItem('userId');
		}
		this.supplierContactsService
			.findAll(
				userId,
				isBuyer,
				page,
				size,
				sortColumn,
				sortOrder,
				filterOptions
			)
			.subscribe((response) => {
				this.loading = false;
				this.totalRecords = response.totalRecordsAll;
				this.supplierContacts = response.all;
				this.supplierContactExcel = this.supplierContacts;
			});
	}

	nullCheck(date) {
		return !date || date === null || date === 'null';
	}

	isValidDates(fromDate, toDate) {
		return moment(fromDate) < moment(toDate);
	}

	exportAsXLSX() {
		const supplierContactExports = [];

		this.supplierContactExcel.forEach((supplierContact) => {
			const supplierContactExport = {};
			supplierContactExport['PSA#'] = supplierContact.psa;
			supplierContactExport['Lead Program'] = supplierContact.program;
			supplierContactExport['Material Group'] = supplierContact.commodity;
			supplierContactExport['Material Group Description'] =
				supplierContact.commodityName;
			supplierContactExport['Supplier'] = supplierContact.supplier;
			supplierContactExport['Updated Supplier'] =
				supplierContact.supplierContact;
			supplierContactExport['From Date'] = supplierContact.fromDate
				? moment(supplierContact.fromDate).format('MM/DD/YYYY HH:MM')
				: supplierContact.fromDate;
			supplierContactExport['To Date'] = supplierContact.toDate
				? moment(supplierContact.toDate).format('MM/DD/YYYY HH:MM')
				: supplierContact.toDate;
			supplierContactExport['Updated By'] = supplierContact.updateBy;
			supplierContactExport['Updated Date'] = supplierContact.updateDate
				? moment(supplierContact.updateDate).format('MM/DD/YYYY HH:MM')
				: supplierContact.updateDate;
			supplierContactExport['Reason'] = supplierContact.status;
			supplierContactExports.push(supplierContactExport);
		});

		this.excelService.exportAsExcelFile(
			supplierContactExports,
			'supplierContacts'
		);
	}

	isMandatoryField() {
		if (this.isMoving === 'Moving') {
			const mandatory = this.fromDate && this.supplier;
			this.isSubmit = !mandatory;

			return mandatory;
		} else if (this.isMoving === 'Vacation') {
			const mandatory = this.toDate && this.fromDate && this.supplier;
			this.isSubmit = !mandatory;

			return mandatory;
		}
	}

	onRowSelection(event) {
		this.dtSelectedRows.push(event.data);
	}

	onRowUnSelection(event) {
		this.dtSelectedRows = this.dtSelectedRows.filter(
			(item) => item !== event.data
		);
	}

	onBulkUpdate() {
		if (!this.dtSelectedRows || this.dtSelectedRows.length === 0) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000,
					severity: 'warn',
					summary: 'Please select PSA(s) from table below to update.'
				});
			}, 30);
			this.dtSelectedRows = [];
			return;
		}

		if (!this.isMandatoryField()) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000,
					severity: 'warn',
					summary: 'Mandatory fields are required.'
				});
			}, 30);
			return;
		}
		const regexp = new RegExp(
			'[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$'
		); // NOSONAR

		if (!regexp.test(this.supplier)) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000,
					severity: 'warn',
					summary: 'Please enter valid supplier email address.'
				});
			}, 30);

			return;
		}

		if (this.isMoving === 'Moving') {
			this.toDate = null;
		}

		//this.buddyBuyerMap['isMoving'] = this.isMoving;
		this.suppContactMap['fromDate'] = this.fromDate;
		this.suppContactMap['toDate'] = this.toDate;
		this.suppContactMap['suppSubContact'] = this.supplier;
		this.suppContactMap['updatedBy'] = sessionStorage.getItem('userId');
		const ids = [];
		for (const dtSelectedRow of this.dtSelectedRows) {
			const id = {
				id: dtSelectedRow.id,
				psa: dtSelectedRow.psa,
				supplier: dtSelectedRow.supplier
			};
			ids.push(id);
		}

		this.suppContactMap['ids'] = ids;

		if (!this.supplier) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000,
					severity: 'warn',
					summary:
						'You need to proide Supplier Contact for Bulk update.'
				});
			}, 30);
		} else if (
			this.isMoving === 'Vacation' &&
			this.nullCheck(this.suppContactMap['toDate'])
		) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000,
					severity: 'warn',
					summary: 'Mandatory fields are required before proceeding.'
				});
			}, 30);
		} else if (
			this.isMoving === 'Moving' &&
			this.nullCheck(this.suppContactMap['fromDate'])
		) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000,
					severity: 'warn',
					summary: 'Mandatory fields are required before proceeding.'
				});
			}, 30);
		} else {
			if (
				this.isMoving === 'Vacation' &&
				!this.isValidDates(
					this.suppContactMap['fromDate'],
					this.suppContactMap['toDate']
				)
			) {
				setTimeout(() => {
					this.messageService.add({
						life: 300000,
						severity: 'warn',
						summary:
							'From date must be smaller than To date for Supplier Contact.'
					});
				}, 30);

				return;
			}

			this.supplierContactsService
				.bulkUpdateSupSubContacts(this.suppContactMap)
				.subscribe((response) => {
					if (response) {
						this.retriveSupplierContact(this.filterOptions);
						this.dtSelectedRows = [];
						setTimeout(() => {
							this.messageService.add({
								life: 300000,
								severity: 'success',
								summary:
									'Supplier Contact information saved successfully ' +
									'for all selected PSAs. Please allow at least 15 minutes ' +
									'for the changes to take effect.'
							});
						}, 30);
					}
				});
		}
	}
}
