/**
 * @author DCHIRUM1
 */
import {Injectable} from '@angular/core';
import {Config} from '../../config';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RfiCostRequestService {
	private config = new Config();

	constructor(private http: HttpClient) {}

	findAll(): Observable<any> {
		return this.http.get(environment.BACKEND_URL + 'edcmservice/rfiCost');
	}

	findByRfiHeaderId(rfiHeaderId, prevRfiNum): Observable<any> {
		return this.http.get(
			environment.BACKEND_URL +
				'edcmservice/rfiCost/rfiHeader/' +
				rfiHeaderId +
				'/' +
				prevRfiNum
		);
	}

	findCosts(rfiCosts, lookups, rfiType, rfiHeaderId) {
		const costs = [];

		lookups.forEach((element) => {
			if (element.rfiType === rfiType) {
				const cost = {
					costBucket: element.name,
					prevRFI: 0,
					curRFI: 0,
					id: 0,
					lookupId: element.id,
					rfiHeaderId
				};

				rfiCosts.forEach((rfiCost) => {
					// tslint:disable-next-line:triple-equals
					if (element.id === rfiCost.lookupId) {
						cost.prevRFI = rfiCost.prevRFI ? rfiCost.prevRFI : 0;
						cost.curRFI = rfiCost.curRFI ? rfiCost.curRFI : 0;
						cost.id = rfiCost.id;
					}
				});

				costs.push(cost);
			}
		});

		return costs;
	}

	saveCosts(
		rfiCosts,
		rfiPrefix,
		rfiNum,
		prevRfiPrefix,
		prevRfiNum
	): Observable<any> {
		const costs = [];
		rfiCosts.forEach((rfiCost) => {
			costs.push(rfiCost);
		});
		return this.http.post(
			environment.BACKEND_URL +
				'edcmservice/rfiCost/' +
				rfiPrefix +
				'/' +
				rfiNum +
				'/' +
				prevRfiPrefix +
				'/' +
				prevRfiNum,
			costs
		);
	}

	copyRFICosts(fromRFI, toRFI): Observable<any> {
		return this.http.post(
			environment.BACKEND_URL +
				'edcmservice/rfiCost/copyRFICost/' +
				fromRFI,
			toRFI
		);
	}
}
