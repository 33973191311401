// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.example1 {
	box-sizing: content-box;
	width: 1009px;
	height: 608px;
	padding: 104px;
	background-position: center;
	background-repeat: no-repeat;
	margin-top: -90px;
	margin-left: 6em;
}
`, "",{"version":3,"sources":["webpack://./src/app/home/home.component.css"],"names":[],"mappings":";AACA;CACC,uBAAuB;CACvB,aAAa;CACb,aAAa;CACb,cAAc;CACd,2BAA2B;CAC3B,4BAA4B;CAC5B,iBAAiB;CACjB,gBAAgB;AACjB","sourcesContent":["\r\n.example1 {\r\n\tbox-sizing: content-box;\r\n\twidth: 1009px;\r\n\theight: 608px;\r\n\tpadding: 104px;\r\n\tbackground-position: center;\r\n\tbackground-repeat: no-repeat;\r\n\tmargin-top: -90px;\r\n\tmargin-left: 6em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
