/**
 * @author DCHIRUM1
 */
import {Component, OnInit} from '@angular/core';
import {MessageService} from 'primeng/api';
import {HelpDocumentService} from '../services/rfi/help-document.service';

@Component({
	selector: 'app-help-document',
	templateUrl: './help-document.component.html',
	styleUrls: ['./help-document.component.css'],
	providers: [MessageService]
})
export class HelpDocumentComponent implements OnInit {
	documentList = [];
	uploadedFiles: any[] = [];
	filetype =
		'application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,' +
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document, video/*';
	maxFileSize = 1000000000;
	contents: string | null = null;
	filename: string;
	category = 'internal';

	constructor(
		private helpDocumentService: HelpDocumentService,
		private messageService: MessageService
	) {}

	ngOnInit() {
		this.getDocumentList();
	}

	onFileUpload(event, fileUploader) {
		for (const file of event.files) {
			this.uploadedFiles.push(file);

			const fileSizeInMB = file.size / (1024 * 1024);
			if (fileSizeInMB > 9) {
				this.messageService.add({
					severity: 'warn',
					summary:
						'File Size is more that 9 mb please reduce size and upload',
					detail: ''
				});

				fileUploader.clear();

				return;
			}
		}

		let count = 0;
		for (const file of event.files) {
			const formData = new FormData();
			formData.append('file', file);

			this.helpDocumentService
				.uploadDocument(formData, this.category)
				.subscribe(
					(response) => {
						count++;

						if (event.files.length === count) {
							// Clear the selected file after upload
							fileUploader.clear();
							this.getDocumentList();

							console.log(response);
							this.messageService.add({
								severity: 'info',
								summary: 'Upload Successful',
								detail: ''
							});
						}
					},
					(error) => {
						console.error(error);
						this.messageService.add({
							severity: 'error',
							summary: 'Upload failed',
							detail: ''
						});
						fileUploader.clear();
					}
				);
		}
	}

	onRowDelete(data) {
		this.helpDocumentService.deleteDocument(data.id).subscribe(() => {
			this.messageService.add({
				severity: 'info',
				summary: 'Help document deleted successfully',
				detail: ''
			});

			this.getDocumentList();
		});
	}

	private getDocumentList() {
		this.helpDocumentService.showDocumentList().subscribe((response) => {
			this.documentList = response;
		});
	}
}
