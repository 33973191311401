/**
 * @author DCHIRUM1
 */
import {Injectable} from '@angular/core';
import {Config} from '../../config';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PartialRfiDraftRequestService {
	private config = new Config();

	// tslint:disable-next-line:indent
	constructor(private http: HttpClient) {}

	findAll(supplier, userId): Observable<any> {
		return this.http.get(
			environment.BACKEND_URL +
				'edcmservice/partial-draft-rfi/' +
				userId +
				'/' +
				supplier
		);
	}

	findById(id): Observable<any> {
		return this.http.get(
			environment.BACKEND_URL + 'edcmservice/partial-draft-rfi/' + id
		);
	}

	deleteById(id): Observable<any> {
		return this.http.delete(
			environment.BACKEND_URL + 'edcmservice/partial-draft-rfi/' + id
		);
	}

	saveDraft(inputData): Observable<any> {
		return this.http.post(
			environment.BACKEND_URL + 'edcmservice/partial-draft-rfi/save',
			inputData
		);
	}

	isDraftAlreadyExists(inputData): Observable<any> {
		return this.http.post(
			environment.BACKEND_URL +
				'edcmservice/partial-draft-rfi/isDraftExists',
			inputData
		);
	}
}
