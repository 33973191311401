import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {MessageService} from 'primeng/api';

@Directive({
	selector: '[decimaNumberVal1]'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class DecimaNumberDirective1 {
	@Input('decimaNumberVal1') minusAccept = 'true';
	private regex = new RegExp(/^-?\d{0,11}(\.\d{0,5})?$/);
	private specialKeys: Array<string> = [
		'Backspace',
		'Tab',
		'End',
		'Home',
		'ArrowLeft',
		'ArrowRight',
		'Del',
		'Delete',
		'Control'
	];

	constructor(
		private el: ElementRef,
		private messageService: MessageService
	) {}

	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		console.log(this.el.nativeElement.value);
		// Allow Backspace, tab, end, and home keys
		if (this.specialKeys.indexOf(event.key) !== -1) {
			return;
		}

		if (event.ctrlKey) {
			if (
				event.key.toLowerCase() === 'a' ||
				event.key.toLowerCase() === 'c' ||
				event.key.toLowerCase() === 'v'
			) {
				return;
			}
		}

		const current: string = this.el.nativeElement.value;
		const position = this.el.nativeElement.selectionStart;
		const next: string = [
			current.slice(0, position),
			event.key === 'Decimal' ? '.' : event.key,
			current.slice(position)
		].join('');

		/*	if(current && current.length>0 &&position===0 ) {
				return;
			}
	*/
		if (this.minusAccept === 'prodTooling') {
			if (event.key === '0') {
				this.messageService.add({
					severity: 'warn',
					summary: 'Warning',
					detail:
						'RFI Estimate for Production Tooling equals zero (0), ' +
						'all previously approved Production Tooling will be removed.'
				});
			}
		}

		if (
			next &&
			(!this.regex.test(String(next)) ||
				next === '.' ||
				next.startsWith('.') ||
				(this.minusAccept === 'false' &&
					(next.includes('.') || next.startsWith('-'))))
		) {
			event.preventDefault();
		}

		if (
			next &&
			(!this.regex.test(String(next)) ||
				next === '.' ||
				next.startsWith('.') ||
				(this.minusAccept === 'prodTooling' && next.startsWith('-')))
		) {
			event.preventDefault();
		}
	}

	@HostListener('paste', ['$event'])
	onPaste(event: ClipboardEvent) {
		const dataToPaste = event.clipboardData.getData('text');

		if (
			dataToPaste &&
			(!this.regex.test(String(dataToPaste)) ||
				dataToPaste === '.' ||
				dataToPaste.startsWith('.') ||
				(this.minusAccept === 'false' && dataToPaste.includes('.')))
		) {
			event.preventDefault();
		}

		// Validate 'dataToPaste' against the regex
		if (
			dataToPaste &&
			(!this.regex.test(String(dataToPaste)) ||
				dataToPaste === '.' ||
				dataToPaste.startsWith('.') ||
				dataToPaste.startsWith('-') ||
				(this.minusAccept !== 'false' && dataToPaste.startsWith('-')))
		) {
			event.preventDefault();
		}
	}
}
