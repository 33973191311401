/**
 * @author DCHIRUM1
 */
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SimpleResponse} from 'src/app/models/SimpleResponse';
import {environment} from '../../../environments/environment';
import {Config} from '../../config';

@Injectable({
	providedIn: 'root'
})
export class SecondDandrService {
	private config = new Config();

	constructor(private http: HttpClient) {}

	findAll(userId, isBuyer): Observable<any> {
		return this.http.get(
			environment.BACKEND_URL +
				'edcmservice/SecondDandR' +
				'/all/' +
				userId +
				'/' +
				isBuyer
		);
	}

	bulkUpdatesecondDandRs(secondDandRMap): Observable<any> {
		return this.http.post(
			environment.BACKEND_URL + 'edcmservice/SecondDandR/bulkUpdate',
			secondDandRMap
		);
	}
	findSecondDandR(secondDandRId): Observable<any> {
		return this.http.get(
			environment.BACKEND_URL + 'edcmservice/SecondDandR/' + secondDandRId
		);
	}

	saveSecondDandR(secondDandR): Observable<any> {
		return this.http.post(
			environment.BACKEND_URL + 'edcmservice/SecondDandR/',
			secondDandR
		);
	}

	validateSecondDandR(secondDandRMap): Observable<SimpleResponse> {
		return this.http.post<SimpleResponse>(
			environment.BACKEND_URL +
				'edcmservice/SecondDandR/validateSecondDandR',
			secondDandRMap
		);
	}
}
