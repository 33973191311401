// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coordinatorsTable {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 99%;
  border: 1px solid black;
  margin-left: 5px;
  margin-right: 10px;
}

table, th, td {
  border: 1px solid black;
}

.section-spacer {
  padding-bottom: 20px;
  padding-top: 25px;
}

.edit-section-spacer {
  padding-bottom: 20px;
  padding-top: 6px;
}

.button-width {
  padding-bottom: 1.5px;
  padding-top: 1.5px;
}

a.disabled {
  color: #050;
  cursor: default;
}

#bannerMessageContainer[_ngcontent-obq-c115] {
  background-color: #fff;
  color: #000;
  border-radius: 10px;
  margin-left: 1%;
}`, "",{"version":3,"sources":["webpack://./src/app/banner/banner.component.scss"],"names":[],"mappings":"AAAA;EACC,yDAAA;EACA,yBAAA;EACA,UAAA;EACA,uBAAA;EACA,gBAAA;EACA,kBAAA;AACD;;AAEA;EACC,uBAAA;AACD;;AAEA;EACC,oBAAA;EACA,iBAAA;AACD;;AAEA;EACC,oBAAA;EACA,gBAAA;AACD;;AAEA;EACC,qBAAA;EACA,kBAAA;AACD;;AAEA;EACC,WAAA;EACA,eAAA;AACD;;AACA;EACC,sBAAA;EACA,WAAA;EACA,mBAAA;EACA,eAAA;AAED","sourcesContent":[".coordinatorsTable {\r\n\tfont-family: \"Trebuchet MS\", Arial, Helvetica, sans-serif;\r\n\tborder-collapse: collapse;\r\n\twidth: 99%;\r\n\tborder: 1px solid black;\r\n\tmargin-left: 5px;\r\n\tmargin-right: 10px;\r\n}\r\n\r\ntable, th, td {\r\n\tborder: 1px solid black;\r\n}\r\n\r\n.section-spacer {\r\n\tpadding-bottom: 20px;\r\n\tpadding-top: 25px;\r\n}\r\n\r\n.edit-section-spacer {\r\n\tpadding-bottom: 20px;\r\n\tpadding-top: 6px;\r\n}\r\n\r\n.button-width {\r\n\tpadding-bottom: 1.5px;\r\n\tpadding-top: 1.5px;\r\n}\r\n\r\na.disabled {\r\n\tcolor: #050;\r\n\tcursor: default;\r\n}\r\n#bannerMessageContainer[_ngcontent-obq-c115] {\r\n\tbackground-color: #fff;\r\n\tcolor: #000;\r\n\tborder-radius: 10px;\r\n\tmargin-left: 1%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
