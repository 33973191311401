/**
 * @author DCHIRUM1
 */
import {Injectable} from '@angular/core';
import {Config} from '../../config';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {formatDate} from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class RfiQuestionRequestService {
	private config = new Config();

	constructor(private http: HttpClient) {}

	findAll(): Observable<any> {
		return this.http.get(
			environment.BACKEND_URL + 'edcmservice/rfiQuestion'
		);
	}

	findByRfiHeaderId(rfiHeaderId: string): Observable<any> {
		return this.http.get(
			environment.BACKEND_URL +
				'edcmservice/rfiQuestion/rfiHeader/' +
				rfiHeaderId
		);
	}

	saveQuestions(rfiQuestions, rfiPrefix, rfiNum): Observable<any> {
		const questions = [];
		rfiQuestions.forEach((rfiQuestion) => {
			questions.push(rfiQuestion);
		});
		return this.http.post(
			environment.BACKEND_URL +
				'edcmservice/rfiQuestion/' +
				rfiPrefix +
				'/' +
				rfiNum,
			questions
		);
	}

	findQuestions(rfiQuestions, lookups, rfiHeaderId) {
		const questions = [];

		lookups.forEach((element) => {
			const question = {
				name: element.name,
				answer: '',
				id: 0,
				lookupId: element.id,
				rfiHeaderId,
				rfiType: element.rfiType
			};

			rfiQuestions.forEach((rfiQuestion) => {
				// tslint:disable-next-line:triple-equals
				if (element.id === rfiQuestion.lookupId) {
					question.answer = rfiQuestion.answer;
					question.id = rfiQuestion.id;
					question.rfiType = element.rfiType;
					if (question.rfiType === 'DATE') {
						if (question.answer) {
							question.answer = formatDate(
								new Date(question.answer),
								'MM/dd/yyyy',
								'en_US'
							);
						}
					}
				}
			});

			questions.push(question);
		});

		return questions;
	}
}
