import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({
	name: 'safeComment',
	pure: false
})
export class CommentPipe implements PipeTransform {
	constructor(protected _sanitizer: DomSanitizer) {}

	transform(items): SafeHtml {
		/* eslint no-underscore-dangle: 0 */
		return this._sanitizer.bypassSecurityTrustHtml(items); // NOSONAR
	}
}
