// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.required {
	color: red;
}

.inputclass{
	float: left;
	box-sizing: border-box;
	padding: 0.5em;
	width: 15%;
	margin-left: -10px;


}
.dateinputclass{
	float: left;
	box-sizing: border-box;
	padding: 0.5em;
	width: 35%;
	margin-top: -30px;
	margin-left:120px;
}

.dateinputclass1{
	float: left;
	box-sizing: border-box;
	padding: 0.5em;
	width: 15%;
	margin-top: -65px;
	margin-left: 920px;
}

.labelclass{
	float: left;
	box-sizing: border-box;
	padding-top: 18px;
	width: 9.5%;

}
.datelabelclass{
	float: right;
	box-sizing: border-box;
	padding-top: 13px;
	width: 90.4%;
}

.datelabelclass1{
	float: right;
	box-sizing: border-box;
	padding-top: 13px;
	width: 90.4%;
	margin-top: -65px;
  margin-right: -750px;
}

.todatelabelclass{
	float: right;
	box-sizing: border-box;
	padding-top: 13px;
	width: 6%;

}

.p-table-with-100 {
	width: 100%;
}

.update-button{
	margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/supplier-contact/supplier-contact.component.css"],"names":[],"mappings":"AAAA;CACC,UAAU;AACX;;AAEA;CACC,WAAW;CACX,sBAAsB;CACtB,cAAc;CACd,UAAU;CACV,kBAAkB;;;AAGnB;AACA;CACC,WAAW;CACX,sBAAsB;CACtB,cAAc;CACd,UAAU;CACV,iBAAiB;CACjB,iBAAiB;AAClB;;AAEA;CACC,WAAW;CACX,sBAAsB;CACtB,cAAc;CACd,UAAU;CACV,iBAAiB;CACjB,kBAAkB;AACnB;;AAEA;CACC,WAAW;CACX,sBAAsB;CACtB,iBAAiB;CACjB,WAAW;;AAEZ;AACA;CACC,YAAY;CACZ,sBAAsB;CACtB,iBAAiB;CACjB,YAAY;AACb;;AAEA;CACC,YAAY;CACZ,sBAAsB;CACtB,iBAAiB;CACjB,YAAY;CACZ,iBAAiB;EAChB,oBAAoB;AACtB;;AAEA;CACC,YAAY;CACZ,sBAAsB;CACtB,iBAAiB;CACjB,SAAS;;AAEV;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,gBAAgB;AACjB","sourcesContent":[".required {\r\n\tcolor: red;\r\n}\r\n\r\n.inputclass{\r\n\tfloat: left;\r\n\tbox-sizing: border-box;\r\n\tpadding: 0.5em;\r\n\twidth: 15%;\r\n\tmargin-left: -10px;\r\n\r\n\r\n}\r\n.dateinputclass{\r\n\tfloat: left;\r\n\tbox-sizing: border-box;\r\n\tpadding: 0.5em;\r\n\twidth: 35%;\r\n\tmargin-top: -30px;\r\n\tmargin-left:120px;\r\n}\r\n\r\n.dateinputclass1{\r\n\tfloat: left;\r\n\tbox-sizing: border-box;\r\n\tpadding: 0.5em;\r\n\twidth: 15%;\r\n\tmargin-top: -65px;\r\n\tmargin-left: 920px;\r\n}\r\n\r\n.labelclass{\r\n\tfloat: left;\r\n\tbox-sizing: border-box;\r\n\tpadding-top: 18px;\r\n\twidth: 9.5%;\r\n\r\n}\r\n.datelabelclass{\r\n\tfloat: right;\r\n\tbox-sizing: border-box;\r\n\tpadding-top: 13px;\r\n\twidth: 90.4%;\r\n}\r\n\r\n.datelabelclass1{\r\n\tfloat: right;\r\n\tbox-sizing: border-box;\r\n\tpadding-top: 13px;\r\n\twidth: 90.4%;\r\n\tmargin-top: -65px;\r\n  margin-right: -750px;\r\n}\r\n\r\n.todatelabelclass{\r\n\tfloat: right;\r\n\tbox-sizing: border-box;\r\n\tpadding-top: 13px;\r\n\twidth: 6%;\r\n\r\n}\r\n\r\n.p-table-with-100 {\r\n\twidth: 100%;\r\n}\r\n\r\n.update-button{\r\n\tmargin-top: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
