/**
 * @author DCHIRUM1
 */
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {OAuthCallbackComponent} from './oauth/oauth-callback.component';
import {UrlConsumerService} from './oauth/url-consumer.service';
import {LoginComponent} from './oauth/login.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {AuthGuardService} from './oauth/auth-guard.service';
import {HomeComponent} from './home/home.component';

import {RfiRequestComponent} from './rfi-request/rfi-request.component';
import {CreateRfiRequestComponent} from './rfi-request/create-rfi-request/create-rfi-request.component';
import {BuyerSupplierComponent} from './buyer-supplier/buyer-supplier.component';
import {ThresholdRequestComponent} from './threshold/threshold.component';
import {CreateThresholdComponent} from './threshold/create-threshold/create-threshold.component';
import {CreateComm2PartbaseComponent} from './comm2-partbase/create-comm2-partbase/create-comm2-partbase.component';
import {Comm2PartbaseRequestComponent} from './comm2-partbase/comm2-partbase.component';
import {NoAccessComponent} from './no-access/no-access.component';
import {CreateBuddyBuyerComponent} from './buddy-buyer/create-buddy-buyer/create-buddy-buyer.component';
import {BuddyBuyerComponent} from './buddy-buyer/buddy-buyer.component';
import {SupplierContactComponent} from './supplier-contact/supplier-contactcomponent';
import {CreateSupplierContactComponent} from './supplier-contact/create-supplier-contact/create-supplier-contact.component';
import {CostEstimatorComponent} from './cost-estimator/cost-estimator.component';
import {CreateCostEstimatorComponent} from './cost-estimator/create-cost-estimator/create-cost-estimator.component';

import {BannerComponent} from './banner/banner.component';
//import { BuddyBuyerComponent } from './buddy-buyer/buddy-buyer.component';
import {CreatePartialRFIsComponent as CreatePartialRFIsComponent} from './partial-rfis/create-partial-rfi-request/create-partial-rfis.component';
import {ManualRfiRequestComponent} from './manual-rfi-request/manual-rfi-request.component';
import {CreateManualRfiRequestComponent} from './manual-rfi-request/create-manual-rfi-request/create-manual-rfi-request.component';
import {PartialDraftRfiRequestComponent} from './partial-rfis/partial-draft-rfi-request.component';
import {PermissionDeniedComponent} from './permission-denied/permission-denied.component';
import {HelpDocumentComponent} from './help-document/help-document.component';
import {HelpDocumentDownloadComponent} from './help-document/download/download.component';
import {CreateSupplierEngineerComponent} from './supplier-engineer/create-supplier-engineer/create-supplier-engineer.component';
import {SupplierEngineerComponent} from './supplier-engineer/supplier-engineer.component';
import {SecondDandrComponent} from './second-dandr/second-dandr.component';
import {CreateSecondDandrComponent} from './second-dandr/create-second-dandr/create-second-dandr.component';

const routes: Routes = [
	{
		path: 'access_token',
		component: OAuthCallbackComponent,
		canActivate: [UrlConsumerService]
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: '',
		redirectTo: '/home',
		pathMatch: 'full'
	},
	{
		path: 'home',
		component: HomeComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'buyer-supplier/:type',
		component: BuyerSupplierComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'rfi-request/:buyerSupplier/:filterName/:filterBy/:status/:back/:searchRfi/:selectedType',
		component: RfiRequestComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'rfi-request/:buyerSupplier/programs/:program/:status/:back/:searchRfi',
		component: RfiRequestComponent,
		canActivate: [AuthGuardService]
	},
	/*	{
		path: 'manual-rfis',
		component: ManualRFIsComponent,
		canActivate: [AuthGuardService]
	},*/
	{
		path: 'create-rfi-request/:buyerSupplier/:mode/:id',
		component: CreateRfiRequestComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'create-rfi-request/:buyerSupplier/:mode/:id/:status/:program/:searchRfi/:back/:selectedType',
		component: CreateRfiRequestComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'manual-rfi-request/:buyerSupplier/:filterName/:filterBy/:status/:back/:searchRfi',
		component: ManualRfiRequestComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'manual-rfi-request/:buyerSupplier/programs/:program/:status/:back/:searchRfi',
		component: RfiRequestComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'create-manual-rfi-request/:buyerSupplier/:mode/:id',
		component: CreateManualRfiRequestComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'create-manual-rfi-request/:buyerSupplier/:mode/:id/:status/:program/:searchRfi/:back/:new',
		component: CreateManualRfiRequestComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'create-partial-rfis/:id',
		component: CreatePartialRFIsComponent
	},
	{
		path: 'partial-draft-rfi-request',
		component: PartialDraftRfiRequestComponent
	},
	{
		path: 'threshold',
		component: ThresholdRequestComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'create-threshold/:mode/:psa',
		component: CreateThresholdComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'create-comm2-partbase/:mode/:partPref/:partBase/:partSuff',
		component: CreateComm2PartbaseComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'comm2-partbase',
		component: Comm2PartbaseRequestComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'buddy-buyer',
		component: BuddyBuyerComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'create-buddy-buyer/:mode/:buddyBuyerId',
		component: CreateBuddyBuyerComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'supplier-contact',
		component: SupplierContactComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'create-supplier-contact/:mode/:supplierContactId',
		component: CreateSupplierContactComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'supplier-engineer',
		component: SupplierEngineerComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'create-supplier-engineer/:mode/:supplierEngineerId',
		component: CreateSupplierEngineerComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'cost-estimator',
		component: CostEstimatorComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'create-cost-estimator/:mode/:costEstimatorId',
		component: CreateCostEstimatorComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'second-dandr',
		component: SecondDandrComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'create-second-dandr/:mode/:seconddandrId',
		component: CreateSecondDandrComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'app-banner',
		component: BannerComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'help-document',
		component: HelpDocumentComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'help-document-download',
		component: HelpDocumentDownloadComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: '404',
		component: NotFoundComponent
	},
	{
		path: 'no-access',
		component: NoAccessComponent
	},
	{
		path: 'permission-denied',
		component: PermissionDeniedComponent
	},
	{
		path: '**',
		redirectTo: '/404'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {useHash: true})],
	exports: [RouterModule]
})
export class AppRoutingModule {}
