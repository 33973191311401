/**
 * @author DCHIRUM1
 */
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RfiRequestService} from '../services/rfi/rfi-request.service';
import * as Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

/** Component that has no functionality other than plain html text in the template */
@Component({
	selector: 'app-buyer-supplier',
	templateUrl: './buyer-supplier.component.html',
	styleUrls: ['./buyer-supplier.component.css']
})
export class BuyerSupplierComponent implements OnInit {
	dataU: any = {};
	dataI: any = {};
	dataP: any;
	dataC: any = {};
	rfiDropUnread = [];
	rfiDropInProg = [];
	rfiProg = [];
	selectedUnReadRfi: any;
	selectedInProgressRfi: any;
	selectedProgram: any;
	programCountResp: any;
	buyerSupplier: any;
	inProgressCount = 0;
	unreadCount = 0;
	completeCount = 0;
	totCount = 0;
	progCompleteCount = 0;
	progInprogressCount = 0;
	progUnreadCount = 0;
	filterBy: any;

	chartJs = Chart;
	chartLabelPlugin = ChartDataLabels;

	plugin = ChartDataLabels;
	options = {
		plugins: {
			datalabels: {
				color: '#000'
			}
		}
	};

	/** empty constructor */
	constructor(
		private rfiService: RfiRequestService,
		private router: Router,
		private activatedRoute: ActivatedRoute
	) {
		//this.chartJs.plugins.Colors;

		this.rfiDropUnread = [
			{
				label: 'Total Unread RFIs by Program',
				value: {by: 'Program', status: 'U'}
			},
			{
				label: 'Total Unread RFIs by Days Aging',
				value: {by: 'DaysAging', status: 'U'}
			}
		];

		this.rfiDropInProg = [
			{
				label: 'Total In Progress RFIs by Program',
				value: {by: 'Program', status: 'I'}
			},
			{
				label: 'Total In Progress RFIs by Days Aging',
				value: {by: 'DaysAging', status: 'I'}
			}
		];
	}

	dynamicColor(index, tot) {
		index = index / tot;
		const r = Math.floor(index * 30);
		const g = Math.floor(index * 60);
		const b = Math.floor(index * 170);
		return 'rgb(' + r + ',' + g + ',' + b + ')';
	}

	dynamicColor1(index) {
		const color = [
			'rgb(211,143,42)',
			'rgb(91,166,188)',
			'rgb(242,110,89)',
			'rgb(134,193,51)',
			'rgb(93,135,37)',
			'rgb(121,114,23)',
			'rgb(2,155,218)',
			'rgb(1,156,88)',
			'rgb(134,57,27)',
			'rgb(204, 255, 102)',
			'rgb(102, 204, 255)',
			'rgb(102, 153, 153)',
			'rgb(255, 128, 128)',
			'rgb(255, 255, 0)',
			'rgb(0, 102, 204)',
			'rgb(255, 153, 204)',
			'rgb(209, 209, 224)',
			'rgb(102, 0, 51)',
			'rgb(255, 204, 230)',
			'rgb(217, 102, 255)',
			'rgb(242, 217, 230)',
			'rgb(128, 223, 255)',
			'rgb(255, 51, 204)',
			'rgb(255, 51, 0)',
			'rgb(255, 153, 0)',
			'rgb(128, 0, 0)',
			'rgb(204, 102, 255)'
		];
		return color[index];
	}

	/** used for functionality that doesn't belong in the constructor but still
	 * needs to be run at the beginning of the lifecycle of the component.
	 */
	ngOnInit() {
		this.inProgressCount = 0;
		this.unreadCount = 0;
		this.retriveRouteParameter();

		sessionStorage.setItem('program', null);
		sessionStorage.setItem('commodity', null);
		sessionStorage.setItem('buyer', null);
		sessionStorage.setItem('siteCode', null);
		sessionStorage.setItem('partNumber', null);
	}

	loadRfiData(response) {
		const count = [];
		const lables = [];
		const colors = [];
		let totCount = 0;

		let index = 0;
		response[0].forEach((value) => {
			if (response[0][index++] !== 0) {
				count.push(value);
			}
		});

		index = 0;
		response[1].forEach((value) => {
			if (response[0][index++] !== 0) {
				lables.push(value);
			}
		});

		index = 0;
		lables.forEach(() => {
			colors.push(this.dynamicColor1(index++));
		});

		const data = {
			labels: lables,
			datasets: [
				{
					data: count,
					backgroundColor: colors,
					hoverBackgroundColor: colors
				}
			]
		};

		totCount = 0;
		count.forEach((cnt) => {
			totCount += cnt;
		});

		data['totCount'] = totCount;
		return data;
	}

	selectData(event, status, typeDropDown) {
		if (status === 'Completed' || status === 'Accepted') {
			status = 'C';
		}
		if (status === 'Inprogress') {
			status = 'I';
		}
		if (status === 'Unread') {
			status = 'U';
		}
		if (event === null) {
			this.filterBy = null;
		}
		if (event != null) {
			if (status === 'C') {
				this.filterBy = this.dataC.labels[event.element.index];
			}
			if (status === 'I') {
				this.filterBy = this.dataI.labels[event.element.index];
			}
			if (status === 'U') {
				this.filterBy = this.dataU.labels[event.element.index];
			}
		}

		if (typeDropDown) {
			if (typeDropDown.by === 'Program') {
				this.router.navigate([
					'rfi-request/' +
						this.buyerSupplier +
						'/program/' +
						this.filterBy +
						'/' +
						status +
						'/' +
						true +
						'/' +
						false
				]);
			}

			if (typeDropDown.by === 'DaysAging') {
				if (this.filterBy) {
					this.filterBy = this.filterBy.replace(' days', '');
					this.router.navigate([
						'rfi-request/' +
							this.buyerSupplier +
							'/dateRange/' +
							this.filterBy +
							'/' +
							status +
							'/' +
							true +
							'/' +
							false
					]);
				} else {
					this.router.navigate([
						'rfi-request/' +
							this.buyerSupplier +
							'/program/' +
							this.filterBy +
							'/' +
							status +
							'/' +
							true +
							'/' +
							false
					]);
				}
			}
		} else {
			if (event === null) {
				this.router.navigate([
					'rfi-request/' +
						this.buyerSupplier +
						'/program/' +
						this.selectedProgram +
						'/' +
						'C' +
						'/' +
						true +
						'/' +
						false
				]);
			}
			if (status != null) {
				this.router.navigate([
					'rfi-request/' +
						this.buyerSupplier +
						'/program/' +
						this.selectedProgram +
						'/' +
						this.dataP.labels[status.element.index].toUpperCase() +
						'/' +
						true +
						'/' +
						false
				]);
			}
		}
	}

	calcProgramRFICount() {
		let tot = 0;
		this.rfiProg.forEach((prog) => {
			if (prog.value) {
				const responseP = this.programCountResp[prog.value];
				const count = responseP[0];
				count.forEach((cnt) => {
					tot += cnt;
				});
			}
		});

		return tot;
	}

	isChartsDataAvailable() {
		return (
			this.unreadCount !== 0 ||
			this.inProgressCount !== 0 ||
			this.totCount !== 0
		);
	}

	loadProgramData() {
		const program = this.selectedProgram;
		let response = [];

		if (this.selectedProgram) {
			response = this.programCountResp[program];
		} else {
			response = this.programCountResp['Completed'];
			//response = this.programCountResp;
			//this.dataP = this.loadRfiData(responseC);
		}

		this.populateProgramRFIs(response);
	}

	changeRfiRetrivalType(selectedItem) {
		console.log(selectedItem);
		const responseI = this.programCountResp['Inprogress'];
		const responseU = this.programCountResp['Unread'];
		//const responseC = this.programCountResp['Completed'];
		const responseIDateRange = this.programCountResp['InprogressDateRange'];
		const responseUDateRange = this.programCountResp['UnreadDateRange'];

		if (selectedItem.by === 'Program') {
			if (selectedItem.status === 'I') {
				this.dataI = this.loadRfiData(responseI);
				this.inProgressCount = this.dataI['totCount'];
			} else {
				this.dataU = this.loadRfiData(responseU);
				this.unreadCount = this.dataU['totCount'];
			}
		} else {
			if (selectedItem.status === 'I') {
				this.dataI = this.loadRfiData(responseIDateRange);
				this.inProgressCount = this.dataI['totCount'];
			} else {
				this.dataU = this.loadRfiData(responseUDateRange);
				this.unreadCount = this.dataU['totCount'];
			}
		}
	}

	isAdminRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		//return apsRoles.includes('eDCM_IT_ADMIN') || apsRoles.includes('PD_Engineer') || apsRoles.includes('eDCM_Super_Buyer');
		return (
			apsRoles.includes('eDCM_IT_ADMIN') ||
			apsRoles.includes('eDCM_Super_Buyer')
		);
	}

	isPdEnggRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return (
			apsRoles.includes('PD_Engineer') || apsRoles.includes('JV Engineer')
		);
	}

	isCostEstimatorRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return (
			apsRoles.includes('CostEstimator') ||
			apsRoles.includes('JV Costestimator')
		);
	}

	isBuyerRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return apsRoles.includes('Buyer') || apsRoles.includes('JV Buyer');
	}

	isSupplierRole(apsRoles) {
		return apsRoles.includes('EDCM_UPDATE');
	}

	private retriveRouteParameter() {
		this.activatedRoute.paramMap.subscribe((params) => {
			this.selectedUnReadRfi = this.rfiDropUnread[0].value;
			this.selectedInProgressRfi = this.rfiDropInProg[0].value;
			this.buyerSupplier = params.get('type');
			this.inProgressCount = 0;
			this.unreadCount = 0;
			this.completeCount = 0;
			this.totCount = 0;
			this.rfiProg = [];
			this.dataP = {};

			const supp = this.buyerSupplier.toLowerCase() === 'supplier';
			let userId = supp
				? sessionStorage.getItem('emailAddress')
				: sessionStorage.getItem('userId');
			const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
			if (this.isAdminRole()) {
				userId = 'eDCM_IT_ADMIN';
			}

			let role = '';

			if (this.isBuyerRole()) {
				role = 'Buyer';
			}

			if (apsRoles.includes('EDCM_UPDATE')) {
				role = 'EDCM_UPDATE';
			}

			if (this.isPdEnggRole()) {
				role = 'PD_Engineer';
			}

			if (this.isCostEstimatorRole()) {
				role = 'CostEstimator';
			}

			if (this.isAdminRole()) {
				role = 'eDCM_IT_ADMIN';
			}

			this.rfiService
				.programCount(supp, userId, role)
				.subscribe((response) => {
					this.programCountResp = response;
					const responseI = response['Inprogress'];
					const responseU = response['Unread'];
					const responseC = response['Completed'];
					this.rfiProg.push({label: 'Select Program', value: null});
					const reduceRes = Object.keys(response).reduce((acc, k) => {
						if (
							!k.includes('Inprogress') &&
							!k.includes('Unread') &&
							!k.includes('Completed')
						) {
							this.rfiProg.push({label: k, value: k});
						}
						return acc;
					});
					console.log('Updated programCount response: ' + reduceRes);

					this.selectedProgram = this.rfiProg[0].value;
					if (this.selectedProgram) {
						this.loadProgramData();
					} else {
						this.populateProgramRFIs(responseC);
					}

					this.dataI = this.loadRfiData(responseI);
					this.inProgressCount += this.dataI['totCount'];
					this.dataU = this.loadRfiData(responseU);
					this.unreadCount += this.dataU['totCount'];

					this.completeCount += this.dataI['totCount'];
					this.totCount = this.calcProgramRFICount();
				});
		});
	}

	private populateProgramRFIs(response: any[]) {
		const count = [];
		const lables = [];
		const colors = [];
		const colorsP = ['#32CD32', '#D3D3D3', '#BD162C', '#24ffff'];

		let index = 0;
		response[0].forEach((value) => {
			if (response[0][index++] !== 0) {
				count.push(value);
			}
		});

		index = 0;
		response[1].forEach((value) => {
			if (response[0][index++] !== 0) {
				lables.push(value);
			}
		});

		index = 0;
		response[0].forEach((value) => {
			if (value !== 0) {
				colors.push(colorsP[index]);
			}
			index++;
		});

		this.progCompleteCount = count.length;
		this.progInprogressCount = count.length;
		this.progUnreadCount = count.length;

		this.dataP = {
			labels: lables,
			datasets: [
				{
					data: count,
					backgroundColor: colors,
					hoverBackgroundColor: colors
				}
			]
		};
	}
}
