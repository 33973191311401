import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'no-access',
	templateUrl: './no-access.component.html',
	styleUrls: ['./no-access.component.css']
})
export class NoAccessComponent implements OnInit {
	ngOnInit() {
		console.log('NoAccessComponent initialized');
	}
}
