// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#bannerMessageContainer {
  border-radius: 10px;
  margin-left: 2%;
  position: relative;
  font-size: 15px;
}

#bannerMsg {
  padding: 5px;
  max-height: 200px;
  min-height: 50px;
  border: 1px solid black;
  display: table;
  margin-left: -40px;
  word-break: break-all;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/banner/banner-message/banner-message.component.scss"],"names":[],"mappings":"AAAA;EACC,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,eAAA;AACD;;AAGA;EACC,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,uBAAA;EACA,cAAA;EACA,kBAAA;EACA,qBAAA;AAAD;;AAGA,UAAA;AACA;EACC,UAAA;AAAD","sourcesContent":["#bannerMessageContainer {\r\n\tborder-radius: 10px;\r\n\tmargin-left: 2%;\r\n\tposition: relative;\r\n\tfont-size: 15px;\r\n\r\n}\r\n\r\n#bannerMsg {\r\n\tpadding: 5px;\r\n\tmax-height: 200px;\r\n\tmin-height: 50px;\r\n\tborder: 1px solid black;\r\n\tdisplay:table;\r\n\tmargin-left:-40px;\r\n\tword-break: break-all;\r\n}\r\n\r\n/* width */\r\n::-webkit-scrollbar {\r\n\twidth: 0px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
