/**
 * @author DCHIRUM1
 */
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ManualRfiEDTCostService {
	constructor(private http: HttpClient) {}

	findByRfiHeaderId(rfiPrefix, rfiNum): Observable<any> {
		return this.http.get(
			environment.BACKEND_URL +
				'edcmservice/manual_edt_cost/' +
				rfiPrefix +
				'/' +
				rfiNum
		);
	}

	saveEDTCosts(edtCosts, rfiPrefix, rfiNum): Observable<any> {
		const rfiToolings = [...edtCosts];
		rfiToolings.forEach((rfiQuestion) => {
			rfiQuestion.finance =
				rfiQuestion.finance != null
					? rfiQuestion.finance === 'true'
					: null;
		});
		return this.http.post(
			environment.BACKEND_URL +
				'edcmservice/manual_edt_cost/' +
				rfiPrefix +
				'/' +
				rfiNum,
			rfiToolings
		);
	}
}
