import {Component, OnDestroy, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {Subscription} from 'rxjs';
import {BannerService} from '../services/rfi/banner.service';
import {BannerDetails} from '../models/BannerDetails';
import {Message} from 'primeng/api';
import {AngularEditorConfig} from '@kolkov/angular-editor';

@Component({
	selector: 'app-banner',
	templateUrl: './banner.component.html',
	styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit, OnDestroy {
	ngOnDestroy(): void {
		this.adminSubscription.forEach((subscription) =>
			subscription.unsubscribe()
		);
	}
	defaultBannerDetails = new BannerDetails({bannerId: 0, bannerMessage: ''});
	bannerDetails: BannerDetails = _.cloneDeep(this.defaultBannerDetails);

	coordinatorMap = new Map<string, string>();
	editCoordinator = false;
	pmtAddEdit = '';
	sectionAddEdit = '';
	coordinatorAddEdit = '';
	emailAddEdit = '';
	validationMessage = {};
	deleteCoordinatorFlag = false;
	confirmNoCdsid = false;
	message: Message[] = [];
	timeoutThirtySeconds = 30000;
	editkey: string;
	adminSubscription: Subscription[] = [];
	displayMode = 'Edit';
	apsRoles;

	editorConfig: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: 'auto',
		minHeight: '10rem',
		maxHeight: 'auto',
		width: 'auto',
		minWidth: '0',
		translate: 'yes',
		enableToolbar: true,
		showToolbar: true,
		placeholder: 'Enter text here...',
		defaultParagraphSeparator: '',
		defaultFontName: '',
		defaultFontSize: '',
		fonts: [
			{class: 'arial', name: 'Arial'},
			{class: 'times-new-roman', name: 'Times New Roman'},
			{class: 'calibri', name: 'Calibri'},
			{class: 'comic-sans-ms', name: 'Comic Sans MS'},
			{class: 'sanserif', name: 'Sans Serif'},
			{class: 'freestyle-script', name: 'Freestyle Script'},
			{class: 'georgia', name: 'Georgia'},
			{class: 'verdana', name: 'Verdana'},
			{class: 'futura', name: 'Futura'}
		],
		sanitize: false,
		toolbarPosition: 'top',
		toolbarHiddenButtons: [['fontSize'], ['insertImage'], ['insertVideo']]
	};

	constructor(private bannerService: BannerService) {}

	ngOnInit() {
		this.apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		this.bannerService.getBannerMessage().subscribe((bannerDetails) => {
			if (bannerDetails) {
				this.bannerDetails = bannerDetails;
				this.bannerService.bannerMessageSubject.next(
					this.bannerDetails.bannerMessage
				);
			}
		});
	}

	saveBannerMessage() {
		this.bannerService
			.saveBannerMessage(this.bannerDetails.bannerMessage)
			.subscribe((bannerDetails: BannerDetails) => {
				this.bannerDetails = bannerDetails;
				this.bannerService.bannerMessageSubject.next(
					this.bannerDetails.bannerMessage
				);
			});
	}

	updateBannerMessage() {
		this.bannerService
			.updateBannerMessage(this.bannerDetails)
			.subscribe((bannerId) => {
				this.bannerDetails.bannerId = bannerId;
				this.bannerService.bannerMessageSubject.next(
					this.bannerDetails.bannerMessage
				);
			});
	}

	deleteBannerMessage() {
		this.bannerService
			.deleteBannerMessage(this.bannerDetails.bannerId)
			.subscribe(() => {
				this.bannerDetails = _.cloneDeep(this.defaultBannerDetails);
				this.bannerService.bannerMessageSubject.next(
					this.bannerDetails.bannerMessage
				);
			});
	}

	createOrUpdateBannerMessage() {
		if (this.bannerDetails.bannerMessage.length > 0) {
			// eslint-disable-next-line no-unused-expressions,@typescript-eslint/no-unused-expressions
			this.bannerDetails.bannerId > 0
				? this.updateBannerMessage()
				: this.saveBannerMessage();
		}
	}
}
