/**
 * @author DCHIRUM1
 */
import {Injectable} from '@angular/core';
import {Config} from '../../config';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SupplierContactService {
	private config = new Config();

	constructor(private http: HttpClient) {}

	findAll(
		userId,
		isBuyer,
		page,
		size,
		sortColumn,
		sortOrder,
		filetrOptions
	): Observable<any> {
		if (!sortColumn) {
			sortColumn = 'updateDate';
		}
		return this.http.post(
			environment.BACKEND_URL +
				'edcmservice/supplierContact' +
				'/all/' +
				userId +
				'/' +
				isBuyer +
				'/' +
				page +
				'/' +
				size +
				'/' +
				sortColumn +
				'/' +
				sortOrder,
			filetrOptions
		);
	}

	findSupplierContact(suppliercontactId): Observable<any> {
		return this.http.get(
			environment.BACKEND_URL +
				'edcmservice/supplierContact/' +
				suppliercontactId
		);
	}

	saveSupplierContact(suppliercontact): Observable<any> {
		return this.http.post(
			environment.BACKEND_URL + 'edcmservice/supplierContact/',
			suppliercontact
		);
	}

	bulkUpdateSupSubContacts(buddyBuyerMap): Observable<any> {
		return this.http.post(
			environment.BACKEND_URL + 'edcmservice/supplierContact/bulkUpdate',
			buddyBuyerMap
		);
	}
}
