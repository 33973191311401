// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-heading {
	margin: 50px auto;
	width: 70%;
	line-height: 35px;
	font-size: 20px;
}

.error-heading p {
	text-align: center;
	color: #007ad9;
	font-size: 35px;
	font-weight: bold;
}

.error-main h1 {
	margin: 10px auto;
	width: -moz-fit-content;
	width: fit-content;
	font-size: 72px;
	color: #007ad9;
}
`, "",{"version":3,"sources":["webpack://./src/app/no-access/no-access.component.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;CACjB,UAAU;CACV,iBAAiB;CACjB,eAAe;AAChB;;AAEA;CACC,kBAAkB;CAClB,cAAc;CACd,eAAe;CACf,iBAAiB;AAClB;;AAEA;CACC,iBAAiB;CACjB,uBAAkB;CAAlB,kBAAkB;CAClB,eAAe;CACf,cAAc;AACf","sourcesContent":[".error-heading {\r\n\tmargin: 50px auto;\r\n\twidth: 70%;\r\n\tline-height: 35px;\r\n\tfont-size: 20px;\r\n}\r\n\r\n.error-heading p {\r\n\ttext-align: center;\r\n\tcolor: #007ad9;\r\n\tfont-size: 35px;\r\n\tfont-weight: bold;\r\n}\r\n\r\n.error-main h1 {\r\n\tmargin: 10px auto;\r\n\twidth: fit-content;\r\n\tfont-size: 72px;\r\n\tcolor: #007ad9;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
