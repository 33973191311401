// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.required {
	color: red;
}

.checkbox-label {
	color: black !important;
}

.center-align {
	display: flex;
	justify-content: center;
}

#rfi-label {
	font-weight: bold;
}

.left40 {
	width: 40%;
	text-align: left;
}

.width15 {
	width: 15%;
	white-space: pre-line;
}

.width25 {
	width: 25%;
}

.width25color {
	width: 25%;
	white-space: pre-line;
	color: rgb(151, 151, 151)
}

.width25Textleft {
	width: 25%;
	text-align: left;
}

.width25ColorTextleft {
	width: 25%;
	color: rgb(151, 151, 151);
	text-align: center;
}

.marginTop15 {
	margin-top: 15px;
}

.blackBoldFont {
	color: #0B0000;
	font-weight: bold;
}

.textAlighLeft {
	text-align: left
}

.textAlighCenter {
	text-align: center
}

.width15Center {
	width: 15%;
	text-align: center;
}

.width40Left {
	width: 40%;
	text-align: left;
}

.width20Left {
	width: 20%;
	text-align: left;
}

.width80 {
	width: 80%
}
.labelclass{
	float: left;
	box-sizing: border-box;
	width: 8.8667%;

}
.inputclass{
	float: left;
	box-sizing: border-box;
	padding: 0.5em;
	width: 500.6667%;


}
`, "",{"version":3,"sources":["webpack://./src/app/supplier-engineer/create-supplier-engineer/create-supplier-engineer.component.css"],"names":[],"mappings":"AAAA;CACC,UAAU;AACX;;AAEA;CACC,uBAAuB;AACxB;;AAEA;CACC,aAAa;CACb,uBAAuB;AACxB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,UAAU;CACV,gBAAgB;AACjB;;AAEA;CACC,UAAU;CACV,qBAAqB;AACtB;;AAEA;CACC,UAAU;AACX;;AAEA;CACC,UAAU;CACV,qBAAqB;CACrB;AACD;;AAEA;CACC,UAAU;CACV,gBAAgB;AACjB;;AAEA;CACC,UAAU;CACV,yBAAyB;CACzB,kBAAkB;AACnB;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,cAAc;CACd,iBAAiB;AAClB;;AAEA;CACC;AACD;;AAEA;CACC;AACD;;AAEA;CACC,UAAU;CACV,kBAAkB;AACnB;;AAEA;CACC,UAAU;CACV,gBAAgB;AACjB;;AAEA;CACC,UAAU;CACV,gBAAgB;AACjB;;AAEA;CACC;AACD;AACA;CACC,WAAW;CACX,sBAAsB;CACtB,cAAc;;AAEf;AACA;CACC,WAAW;CACX,sBAAsB;CACtB,cAAc;CACd,gBAAgB;;;AAGjB","sourcesContent":[".required {\n\tcolor: red;\n}\n\n.checkbox-label {\n\tcolor: black !important;\n}\n\n.center-align {\n\tdisplay: flex;\n\tjustify-content: center;\n}\n\n#rfi-label {\n\tfont-weight: bold;\n}\n\n.left40 {\n\twidth: 40%;\n\ttext-align: left;\n}\n\n.width15 {\n\twidth: 15%;\n\twhite-space: pre-line;\n}\n\n.width25 {\n\twidth: 25%;\n}\n\n.width25color {\n\twidth: 25%;\n\twhite-space: pre-line;\n\tcolor: rgb(151, 151, 151)\n}\n\n.width25Textleft {\n\twidth: 25%;\n\ttext-align: left;\n}\n\n.width25ColorTextleft {\n\twidth: 25%;\n\tcolor: rgb(151, 151, 151);\n\ttext-align: center;\n}\n\n.marginTop15 {\n\tmargin-top: 15px;\n}\n\n.blackBoldFont {\n\tcolor: #0B0000;\n\tfont-weight: bold;\n}\n\n.textAlighLeft {\n\ttext-align: left\n}\n\n.textAlighCenter {\n\ttext-align: center\n}\n\n.width15Center {\n\twidth: 15%;\n\ttext-align: center;\n}\n\n.width40Left {\n\twidth: 40%;\n\ttext-align: left;\n}\n\n.width20Left {\n\twidth: 20%;\n\ttext-align: left;\n}\n\n.width80 {\n\twidth: 80%\n}\n.labelclass{\n\tfloat: left;\n\tbox-sizing: border-box;\n\twidth: 8.8667%;\n\n}\n.inputclass{\n\tfloat: left;\n\tbox-sizing: border-box;\n\tpadding: 0.5em;\n\twidth: 500.6667%;\n\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
