/**
 * @author DCHIRUM1
 */
import {BrowserModule} from '@angular/platform-browser';
import {
	CUSTOM_ELEMENTS_SCHEMA,
	NgModule,
	NO_ERRORS_SCHEMA
} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NavComponent} from './nav/nav.component';
import {ButtonModule} from 'primeng/button';
import {MenubarModule} from 'primeng/menubar';
import {PanelMenuModule} from 'primeng/panelmenu';
import {SidebarModule} from 'primeng/sidebar';

import {AppRoutingModule} from './app-routing.module';
import {HomeComponent as HomeComponent} from './home/home.component';
import {OauthModule} from './oauth/oauth.module';
import {NotFoundComponent} from './not-found/not-found.component';
import {HttpRequestInterceptor} from './services/http-request-interceptor.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {TableModule} from 'primeng/table';
import {PaginatorModule} from 'primeng/paginator';
import {DialogModule} from 'primeng/dialog';
import {ToastModule} from 'primeng/toast';

import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {MessagesModule} from 'primeng/messages';

import {RfiRequestComponent} from './rfi-request/rfi-request.component';

import {CreateRfiRequestComponent} from './rfi-request/create-rfi-request/create-rfi-request.component';
import {SelectButtonModule} from 'primeng/selectbutton';
import {DropdownModule} from 'primeng/dropdown';
import {CalendarModule} from 'primeng/calendar';
import {TabViewModule} from 'primeng/tabview';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService, MessageService} from 'primeng/api';
import {BuyerSupplierComponent} from './buyer-supplier/buyer-supplier.component';
import {ThresholdRequestComponent} from './threshold/threshold.component';
import {CreateThresholdComponent} from './threshold/create-threshold/create-threshold.component';
import {Comm2PartbaseRequestComponent} from './comm2-partbase/comm2-partbase.component';
import {CreateComm2PartbaseComponent} from './comm2-partbase/create-comm2-partbase/create-comm2-partbase.component';
import {ExcelService} from './services/excel.service';
import {BuddyBuyerComponent} from './buddy-buyer/buddy-buyer.component';
import {CreateBuddyBuyerComponent} from './buddy-buyer/create-buddy-buyer/create-buddy-buyer.component';
import {SupplierContactComponent} from './supplier-contact/supplier-contactcomponent';
import {CreateSupplierContactComponent} from './supplier-contact/create-supplier-contact/create-supplier-contact.component';
import {NoAccessComponent} from './no-access/no-access.component';
import {DecimaNumberDirective} from './rfi-request/create-rfi-request/DecimaNumberDirective';
import {DecimaNumberDirective1} from './manual-rfi-request/create-manual-rfi-request/DecimaNumberDirective1';
import {CostEstimatorComponent} from './cost-estimator/cost-estimator.component';
import {CreateCostEstimatorComponent} from './cost-estimator/create-cost-estimator/create-cost-estimator.component';

import {BannerComponent} from './banner/banner.component';
import {BannerMessageComponent} from './banner/banner-message/banner-message.component';

import {CreatePartialRFIsComponent as CreatePartialRFIsComponent} from './partial-rfis/create-partial-rfi-request/create-partial-rfis.component';
import {ManualRfiRequestComponent} from './manual-rfi-request/manual-rfi-request.component';
import {CreateManualRfiRequestComponent} from './manual-rfi-request/create-manual-rfi-request/create-manual-rfi-request.component';

import {PartialDraftRfiRequestComponent} from './partial-rfis/partial-draft-rfi-request.component';

import {MessageModule} from 'primeng/message';
import {PermissionDeniedComponent} from './permission-denied/permission-denied.component';
import {HelpDocumentComponent} from './help-document/help-document.component';
import {HelpDocumentDownloadComponent} from './help-document/download/download.component';
import {FieldsetModule} from 'primeng/fieldset';
import {ChartModule} from 'primeng/chart';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {AccordionModule} from 'primeng/accordion';
import {TooltipModule} from 'primeng/tooltip';
import {ChipsModule} from 'primeng/chips';
import {FileUploadModule} from 'primeng/fileupload';
import {RadioButtonModule} from 'primeng/radiobutton';

import {MatTooltipModule} from '@angular/material/tooltip';
import {MatStepperModule} from '@angular/material/stepper';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
//import {AngularResizeEventModule} from 'angular-resize-event';
import {FooterComponent} from './footer/footer.component';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {CommentPipe} from './banner/comment-pipe';
import {CreateSupplierEngineerComponent} from './supplier-engineer/create-supplier-engineer/create-supplier-engineer.component';
import {SupplierEngineerComponent} from './supplier-engineer/supplier-engineer.component';
import {CreateSecondDandrComponent} from './second-dandr/create-second-dandr/create-second-dandr.component';
import {SecondDandrComponent} from './second-dandr/second-dandr.component';

@NgModule({
	declarations: [
		AppComponent,
		NavComponent,
		NotFoundComponent,
		HomeComponent,
		BuyerSupplierComponent,
		RfiRequestComponent,
		CreateRfiRequestComponent,
		ThresholdRequestComponent,
		CreateThresholdComponent,
		Comm2PartbaseRequestComponent,
		CreateComm2PartbaseComponent,
		DecimaNumberDirective,
		DecimaNumberDirective1,
		NoAccessComponent,
		BuddyBuyerComponent,
		CreateBuddyBuyerComponent,
		SupplierContactComponent,
		CreateSupplierContactComponent,
		SupplierEngineerComponent,
		CreateSupplierEngineerComponent,
		CostEstimatorComponent,
		CreateCostEstimatorComponent,
		BannerComponent,
		BannerMessageComponent,
		CreatePartialRFIsComponent,
		PartialDraftRfiRequestComponent,
		ManualRfiRequestComponent,
		CreateManualRfiRequestComponent,
		PermissionDeniedComponent,
		HelpDocumentComponent,
		HelpDocumentDownloadComponent,
		FooterComponent,
		CommentPipe,
		CreateSecondDandrComponent,
		SecondDandrComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule,
		AppRoutingModule,
		ButtonModule,
		MenubarModule,
		PanelMenuModule,

		SidebarModule,
		TableModule,
		PaginatorModule,
		HttpClientModule,
		InputTextModule,
		InputTextareaModule,
		DialogModule,
		ButtonModule,
		OauthModule,
		FormsModule,
		ToastModule,
		ProgressSpinnerModule,
		MessagesModule,
		ReactiveFormsModule,
		MatStepperModule,
		MatInputModule,
		MatButtonModule,
		MatAutocompleteModule,
		FieldsetModule,
		FormsModule,
		SelectButtonModule,
		TabViewModule,
		DropdownModule,
		CalendarModule,
		ConfirmDialogModule,
		ChartModule,
		BreadcrumbModule,
		RadioButtonModule,
		ScrollPanelModule,
		AccordionModule,
		MatTooltipModule,
		TooltipModule,
		ChipsModule,
		MessageModule,
		FileUploadModule,
		//AngularResizeEventModule,
		AngularEditorModule
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
	providers: [
		MessageService,
		ConfirmationService,
		ExcelService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpRequestInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
