// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left40 {
	width: 40%;
	text-align: left;
}

.width15 {
	width: 15%;
	white-space: pre-line;
}

.rfiRedback {
	background-color: rgb(216, 124, 148);
}


:host ::ng-deep  .p-dropdown .p-dropdown-clear-icon {
	position: absolute;
	right: 29px;
	top: 50%;
	font-size: 0.7em;
	height: 1em;
	margin-top: -0.5em;
}

:host ::ng-deep  .pi {
	font-size: 0.7rem;
}

:host ::ng-deep .siteCode1-dropdown > .p-dropdown {
	display: flex !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/partial-rfis/create-partial-rfi-request/create-partial-rfis.component.css"],"names":[],"mappings":"AAAA;CACC,UAAU;CACV,gBAAgB;AACjB;;AAEA;CACC,UAAU;CACV,qBAAqB;AACtB;;AAEA;CACC,oCAAoC;AACrC;;;AAGA;CACC,kBAAkB;CAClB,WAAW;CACX,QAAQ;CACR,gBAAgB;CAChB,WAAW;CACX,kBAAkB;AACnB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,wBAAwB;AACzB","sourcesContent":[".left40 {\r\n\twidth: 40%;\r\n\ttext-align: left;\r\n}\r\n\r\n.width15 {\r\n\twidth: 15%;\r\n\twhite-space: pre-line;\r\n}\r\n\r\n.rfiRedback {\r\n\tbackground-color: rgb(216, 124, 148);\r\n}\r\n\r\n\r\n:host ::ng-deep  .p-dropdown .p-dropdown-clear-icon {\r\n\tposition: absolute;\r\n\tright: 29px;\r\n\ttop: 50%;\r\n\tfont-size: 0.7em;\r\n\theight: 1em;\r\n\tmargin-top: -0.5em;\r\n}\r\n\r\n:host ::ng-deep  .pi {\r\n\tfont-size: 0.7rem;\r\n}\r\n\r\n:host ::ng-deep .siteCode1-dropdown > .p-dropdown {\r\n\tdisplay: flex !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
