/**
 * @author DCHIRUM1
 */
import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {RfiThresholdRequestService} from 'src/app/services/rfi/rfi-threshold-request.service';

@Component({
	selector: 'create-threshold',
	templateUrl: './create-threshold.component.html',
	providers: [MessageService]
})
export class CreateThresholdComponent implements OnInit {
	private psa;
	private mode;
	isEdit;
	rfiThreshold: any;
	private commodities;
	private programs;
	psaList = [];
	selectedPsa: any;
	private threasholdValues = [];
	breadCrumItems: MenuItem[];
	isSubmit;

	constructor(
		private activatedRoute: ActivatedRoute,
		private rfiThresholdRequestService: RfiThresholdRequestService,
		private messageService: MessageService,
		private confirmationService: ConfirmationService,
		private fb: FormBuilder
	) {
		for (let count = 0; count <= 20; count++) {
			this.threasholdValues.push({name: count + '', value: count});
		}
	}

	ngOnInit(): void {
		this.rfiThreshold = {psa: '', mcost: 10, tcost: 10};
		this.retrieveRouteParameter();
	}

	isMandatoryFields() {
		const mandatory = this.rfiThreshold.psa;
		this.isSubmit = !mandatory;

		return mandatory;
	}

	isBuyerRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return apsRoles.includes('Buyer') || apsRoles.includes('JV Buyer');
	}

	isPdEnggRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return (
			apsRoles.includes('PD_Engineer') || apsRoles.includes('JV Engineer')
		);
	}

	isCostEstimatorRole() {
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		return (
			apsRoles.includes('CostEstimator') ||
			apsRoles.includes('JV Costestimator')
		);
	}

	onSave() {
		if (!this.isMandatoryFields()) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000,
					severity: 'warn',
					summary: 'Mandatory fields are required.'
				});
			}, 30);
			return;
		}
		const rfiThresholdObj: object = {
			psa: this.rfiThreshold.psa.toString(),
			mcost: this.rfiThreshold.mcost,
			tcost: this.rfiThreshold.tcost
		};

		let userId = sessionStorage.getItem('userId');
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (
			apsRoles.includes('eDCM_IT_ADMIN') ||
			apsRoles.includes('eDCM_Super_Buyer') ||
			this.isPdEnggRole()
		) {
			userId = 'eDCM_IT_ADMIN';
		}

		this.rfiThresholdRequestService
			.saveThreshold(rfiThresholdObj, this.isEdit, userId)
			.subscribe((response) => {
				if (response) {
					setTimeout(() => {
						this.messageService.add({
							life: 300000,
							severity: 'success',
							summary: 'Threshold saved successfully.'
						});
					}, 30);
				} else {
					setTimeout(() => {
						this.messageService.add({
							life: 300000,
							severity: 'warn',

							summary:
								'Threshold for selected PSA is already set, please pick a different PSA or edit existing Threshold.'
						});
					}, 30);
				}
			});
	}

	fetchThresholdData(psa) {
		let userId = sessionStorage.getItem('userId');
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (
			apsRoles.includes('eDCM_IT_ADMIN') ||
			apsRoles.includes('eDCM_Super_Buyer') ||
			apsRoles.includes('PD_Engineer')
		) {
			userId = 'eDCM_IT_ADMIN';
		}

		this.rfiThresholdRequestService
			.getPSAList(userId)
			.subscribe((response) => {
				this.psaList = response;
				if (this.isEdit) {
					let userId = sessionStorage.getItem('userId');
					const apsRoles = JSON.parse(
						sessionStorage.getItem('apsRoles')
					);
					if (
						apsRoles.includes('eDCM_IT_ADMIN') ||
						apsRoles.includes('eDCM_Super_Buyer') ||
						this.isPdEnggRole()
					) {
						userId = 'eDCM_IT_ADMIN';
					}
					this.rfiThresholdRequestService
						.findThresholdForPsa(psa, userId)
						.subscribe((response) => {
							this.rfiThreshold = response;
							this.selectedPsa = {value: response.psa + ''};
							console.log(this.rfiThreshold);
						});
				}
			});
	}

	updatePsa() {
		this.rfiThreshold.psa = this.selectedPsa.value.toString();
	}

	// eslint-disable-next-line id-blacklist,id-denylist
	numberOnly(event, number): boolean {
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		// eslint-disable-next-line id-blacklist,id-denylist
		const newVal = number * 10 + (charCode - 48);
		if (newVal > 40) {
			return false;
		}

		return true;
	}

	private retrieveRouteParameter() {
		this.activatedRoute.paramMap.subscribe((params) => {
			this.psa = params.get('psa');
			this.mode = params.get('mode');
			this.isEdit = this.mode === 'edit';

			this.fetchThresholdData(this.psa);
			const addEdit = this.isEdit ? 'Edit Threshold' : 'Add Threshold';

			this.breadCrumItems = [
				{
					label: 'Buyer Home Page',
					url: '#/buyer-supplier/buyer',
					target: '_self'
				},
				{
					label: 'Manage Threshold',
					url: '#/threshold',
					target: '_self'
				},
				{label: addEdit}
			];
		});
	}
}
