/**
 * @author DCHIRUM1
 */
import {environment} from '../environments/environment';
import {HttpHeaders} from '@angular/common/http';

export class Config {
	url = environment.BACKEND_URL;
	env = environment.envName;
	ADFS_SERVICE_URL = environment.ADFS_URL;
	ADFS_RESOURCE_ID = environment.ADFS_RESOURCE;
	ADFS_CLIENT_ID = environment.ADFS_CLIENT_ID;
	APP_URL =
		location.hostname === 'localhost'
			? location.origin
			: 'https://' + location.hostname;
	AUTH_ROUTE =
		this.ADFS_SERVICE_URL +
		'?resource=' +
		this.ADFS_RESOURCE_ID +
		'&response_type=token+id_token' +
		'&client_id=' +
		this.ADFS_CLIENT_ID +
		'&redirect_uri=' +
		encodeURIComponent(window.location.origin + '/');
	httpOptions: any = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization:
				'Bearer ' + sessionStorage.getItem('encodedAccessToken')
		})
	};
}
