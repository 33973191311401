import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import moment from 'moment';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {CostEstimatorService} from '../../services/rfi/cost-estimator.service';

@Component({
	selector: 'create-cost-estimator',
	templateUrl: './create-cost-estimator.component.html',
	styleUrls: ['./create-cost-estimator.component.css']
})
export class CreateCostEstimatorComponent implements OnInit {
	private costEstimatorId;
	private mode;
	isEdit;
	costEstimator: any = {};
	breadCrumItems: MenuItem[];
	private isFromDateChange = false;
	private isToDateChange = false;
	private orgFromDate;
	private orgToDate;
	private buyerList = [];
	private selectedCostEstimator;
	private costEstimatorMap = {};
	isCostEstimator = false;
	private isUpdateCostEstimator = false;
	private validationMessage;
	isSubmit;
	minDate: Date | undefined;
	constructor(
		private activatedRoute: ActivatedRoute,
		private costEstimatorService: CostEstimatorService,
		private messageService: MessageService,
		private confirmationService: ConfirmationService,
		private fb: FormBuilder
	) {}

	ngOnInit(): void {
		this.costEstimator.isMoving = 'Moving';
		this.retrieveRouteParameter();
		const today = new Date();
		const month = today.getMonth() + 1;
		const year = today.getFullYear();
		const prevMonth = month === 0 ? 11 : month - 1;
		const prevYear = prevMonth === 11 ? year - 1 : year;
		this.minDate = new Date();
		this.minDate.setMonth(prevMonth);
		this.minDate.setFullYear(prevYear);
	}

	onFromDateChange() {
		this.isFromDateChange = true;
	}

	private onToDateChange() {
		this.isToDateChange = true;
	}

	onSave() {
		if (!this.isMandatoryField()) {
			this.validationMessage = 'Costestimator is required';
			setTimeout(() => {
				this.messageService.add({
					life: 300000,
					severity: 'warn',
					summary: 'Mandatory fields are required.'
				});
			}, 30);
			return;
		}

		if (!this.isFromDateChange) {
			this.costEstimator.fromDate = this.orgFromDate;
		}

		if (!this.isToDateChange) {
			this.costEstimator.toDate = this.orgToDate;
		}

		if (this.costEstimator.isMoving === 'Moving') {
			this.costEstimator.toDate = null;
		}
		this.costEstimator.updateBy = sessionStorage.getItem('userId');
		this.costEstimator.createdBy = sessionStorage.getItem('userId');
		if (
			this.costEstimator.isMoving === 'Vacation' &&
			!this.costEstimator.toDate
		) {
			this.messageService.add({
				severity: 'warn',
				summary: 'Warning',
				detail: 'You need to set both From and To date for Update Cost Estimator.'
			});
		} else if (
			this.costEstimator.isMoving === 'Moving' &&
			!this.costEstimator.fromDate
		) {
			this.messageService.add({
				severity: 'warn',
				summary: 'Warning',
				detail: 'You need to set From date for Update Cost Estimator.'
			});
		} else {
			this.costEstimatorMap['buddyCostEstimator'] =
				this.costEstimator.buddyCostEstimator;
			this.costEstimatorMap['updatedBy'] =
				sessionStorage.getItem('userId');
			this.costEstimatorService
				.validateCostEstmator(this.costEstimatorMap)
				.subscribe((responseUpdate) => {
					if (responseUpdate.status === 'Failure') {
						this.costEstimatorService
							.saveCostEstimator(this.costEstimator)
							.subscribe((response) => {
								if (response) {
									this.messageService.add({
										severity: 'success',
										summary: 'Success',
										detail: 'Cost Estimator ID saved successfully. Please allow at least 15 minutes for the changes to take effect.'
									});

									this.costEstimator.fromDate = moment(
										this.costEstimator.fromDate
									).format('MM/DD/YYYY');
									if (this.costEstimator.toDate) {
										this.costEstimator.toDate = moment(
											this.costEstimator.toDate
										).format('MM/DD/YYYY');
										this.costEstimator.isMoving =
											'Vacation';
									} else {
										this.costEstimator.isMoving = 'Moving';
									}
									this.selectedCostEstimator =
										this.costEstimator.costEstimator;
								}
							});
					}
				});
		}
	}

	private fetchCostEstimator() {
		const userId = sessionStorage.getItem('userId');
		console.log(userId);
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (
			apsRoles.includes('eDCM_IT_ADMIN') ||
			apsRoles.includes('eDCM_Super_Buyer')
		) {
			//userId = 'eDCM_IT_ADMIN';
		}

		this.costEstimatorService
			.findCostEstimator(this.costEstimatorId)
			.subscribe((resObj) => {
				this.costEstimator = resObj;
				this.costEstimator.isMoving = 'Moving';
				this.orgFromDate = this.costEstimator.fromDate;

				if (this.costEstimator.fromDate) {
					this.costEstimator.fromDate = moment(
						this.costEstimator.fromDate
					).format('MM/DD/YYYY');
				}

				if (this.costEstimator.toDate) {
					this.costEstimator.isMoving = 'Vacation';
					this.orgToDate = this.costEstimator.toDate;
					this.costEstimator.toDate = moment(
						this.costEstimator.toDate
					).format('MM/DD/YYYY');
				}
			});
	}

	private onBuyerChange() {
		this.costEstimator.costEstimator = this.selectedCostEstimator;
	}

	private retrieveRouteParameter() {
		this.activatedRoute.paramMap.subscribe((params) => {
			this.costEstimatorId = params.get('costEstimatorId');
			this.mode = params.get('mode');
			this.isEdit = this.mode === 'edit';

			// const addEdit = this.isEdit
			// 	? 'Edit Update Cost Estimator'
			// 	: 'Add Update Cost Estimator Contact';

			if (this.isEdit) {
				this.fetchCostEstimator();
			}

			this.breadCrumItems = [
				{
					label: 'Buyer Home Page',
					url: '#/buyer-supplier/buyer',
					target: '_self'
				},
				{
					label: 'Update Cost Estimator',
					url: '#/cost-estimator',
					target: '_self'
				},
				{label: 'Edit Cost Estimator'}
			];
		});
	}

	validateCostEstimator(): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			this.costEstimatorMap['buddyCostEstimator'] =
				this.costEstimator.buddyCostEstimator;
			this.costEstimatorMap['updatedBy'] =
				sessionStorage.getItem('userId');

			this.costEstimatorService
				.validateCostEstmator(this.costEstimatorMap)
				.subscribe((response) => {
					if (response.status === 'Success') {
						this.isCostEstimator = true;
						this.validationMessage = response.validationMessage;
						resolve(true);
					} else {
						this.isCostEstimator = false;
						resolve(false);
					}
				});
		});
	}
	isMandatoryField() {
		if (this.costEstimator.isMoving === 'Moving') {
			const mandatory =
				this.costEstimator.fromDate &&
				this.costEstimator.buddyCostEstimator;
			this.isSubmit = !mandatory;

			return mandatory;
		} else if (this.costEstimator.isMoving === 'Vacation') {
			const mandatory =
				this.costEstimator.toDate &&
				this.costEstimator.fromDate &&
				this.costEstimator.buddyCostEstimator;
			this.isSubmit = !mandatory;

			return mandatory;
		}
	}
	restrictSpecialCharacters(event: KeyboardEvent) {
		const allowedKeys = [
			'Backspace',
			'Delete',
			'ArrowLeft',
			'ArrowRight',
			'Tab'
		];
		if (
			!allowedKeys.includes(event.key) &&
			!/[a-zA-Z0-9\s]/.test(event.key)
		) {
			event.preventDefault();
		}
	}
}
