// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-table-with-100 {
	width: 100%;
}

.rfq-text {
	color: red;
	font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/app/partial-rfis/partial-draft-rfi-request.component.css"],"names":[],"mappings":"AAAA;CACC,WAAW;AACZ;;AAEA;CACC,UAAU;CACV,iBAAiB;AAClB","sourcesContent":[".p-table-with-100 {\n\twidth: 100%;\n}\n\n.rfq-text {\n\tcolor: red;\n\tfont-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
