import {Injectable} from '@angular/core';
import {Config} from '../../config';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {BannerDetails} from '../../models/BannerDetails';
import {environment} from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class BannerService {
	bannerUrl = `${environment.BACKEND_URL + 'edcmservice'}/banner`;
	bannerMessageSubject = new Subject<string>();
	notificationSubject = new Subject<string>();
	private config = new Config();

	constructor(private http: HttpClient) {}

	getBannerMessage(): Observable<BannerDetails> {
		return this.http.get<BannerDetails>(this.bannerUrl);
	}

	saveBannerMessage(message: string): Observable<BannerDetails> {
		return this.http.post<BannerDetails>(this.bannerUrl, message);
	}

	updateBannerMessage(bannerDetails: BannerDetails): Observable<number> {
		return this.http.put<number>(this.bannerUrl, bannerDetails);
	}

	deleteBannerMessage(id) {
		return this.http.put(this.bannerUrl.concat('/').concat(id), null);
	}
}
