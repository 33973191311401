import {formatDate} from '@angular/common';
import * as _ from 'lodash';

export class CommonPdfGenerator {
	getPdfTitle(title) {
		return {
			title,
			author: 'eDCM',
			subject: 'Automated RFI Details'
		};
	}

	defaultStyle() {
		return {
			alignment: 'justify'
		};
	}

	buildTableLayout(color1, color2) {
		return {
			hLineColor(i, node) {
				return i === 0 || i === node.table.body.length
					? color1
					: color2;
			},
			vLineColor(i, node) {
				return i === 0 || i === node.table.widths.length
					? color1
					: color2;
			}
		};
	}

	getMappedValue(key, mapValues) {
		return key == null || key === undefined || key === ''
			? ' '
			: key + ' - ' + mapValues.get(key);
	}

	displayCarryOver(submissionDetails) {
		return (
			(submissionDetails.keyNonKeyCommodity === 'Key Commodity' ||
				submissionDetails.keyNonKeyCommodity === 'Non-Key Commodity') &&
			(submissionDetails.sourcingApproach === 'Pre-Source' ||
				submissionDetails.sourcingApproach === 'Market Test' ||
				submissionDetails.sourcingApproach === 'Design Competition' ||
				submissionDetails.sourcingApproach === 'BoB / BoB EC') &&
			submissionDetails.degreeOfChange &&
			submissionDetails.degreeOfChange === 'CO'
		);
	}

	checkRiskData(data) {
		if (data === 'default') {
			return ' ';
		} else if (data == null || data === undefined || data === '') {
			return 'Missing SRA';
		} else {
			return this.buildRiskText(data);
		}
	}

	buildRiskText(risk) {
		if (risk === 'GREEN') {
			return 'No mitigation plans needed';
		} else if (risk === 'YELLOW') {
			return 'Mitigation Plans May Be Required';
		} else if (risk === 'RED') {
			return 'Mitigation Plans Required';
		} else {
			return 'Data Not Available, Assessment Not Possible';
		}
	}

	checkData(data) {
		if (data == null || data === undefined) {
			return ' ';
		} else {
			return data;
		}
	}

	changeDate(date) {
		if (date === ' ' || date === '') {
			return date;
		}
		const dateValue = formatDate(date, 'MM/dd/yyyy', 'en-US');
		return dateValue.includes('1970') || dateValue.includes('1969')
			? ' '
			: dateValue;
	}

	getVersionData(version, requestFor) {
		if (requestFor === 'ADS') {
			return version ? version + 1 : '1';
		} else {
			return version ? version : '0';
		}
	}

	getDirectedTierValues(submissionDetails) {
		if (submissionDetails.directedTier2 === 'No') {
			return 'No';
		} else {
			return 'Yes - ' + submissionDetails.directedTier2Type;
		}
	}

	getValueFromCdsIdMap(cdsIdMap, cdsId) {
		if (_.isEmpty(cdsId) || _.isEmpty(cdsIdMap)) {
			return '';
		} else {
			const name = cdsIdMap[cdsId.trim()];
			return name === undefined ? '' : name;
		}
	}

	getNameForMultipleCdsIds(cdsIdMap, cdsId) {
		let pdLead = '';
		if (_.isEmpty(cdsId) || _.isEmpty(cdsIdMap)) {
			return '';
		} else {
			const cdsIdArray = cdsId.split(';');
			cdsIdArray.forEach((cds) => {
				pdLead = pdLead + cdsIdMap[cds.trim()] + '\n';
			});
			return pdLead;
		}
	}

	getGcstCoordinator(cdsIdMap, gcstCoordinator) {
		if (_.isEmpty(gcstCoordinator)) {
			return '';
		} else {
			const cdsId = gcstCoordinator.split('-')[2].trim();
			return this.getValueFromCdsIdMap(cdsIdMap, cdsId);
		}
	}

	formatText(text) {
		if (_.isEmpty(text)) {
			return '';
		} else {
			return '\n' + text;
		}
	}

	formatTextForExceptionTab(text) {
		if (_.isEmpty(text)) {
			return '';
		} else {
			return text;
		}
	}

	formatUploadDocumentsNames(uploadDetails) {
		let uploadedFiles = '';
		uploadDetails.forEach((files) => {
			const temporaryFiles = files.toString();
			uploadedFiles = uploadedFiles + temporaryFiles + '\r\n';
		});
		return uploadedFiles;
	}

	getFfRating(ffRating) {
		if (_.isEmpty(ffRating)) {
			return '';
		} else {
			return ffRating === '-1' ? 'No Data to Display' : ffRating;
		}
	}

	getFfrRatingColor(ffrRating: string) {
		if (ffrRating === 'G') {
			return 'lawngreen';
		} else if (ffrRating === 'R') {
			return 'orangered';
		} else if (ffrRating === 'Y') {
			return 'yellow';
		} else if (ffrRating === 'O') {
			return 'orange';
		} else if (ffrRating === '-1') {
			return 'deepskyblue';
		} else {
			return 'opaque';
		}
	}
}
