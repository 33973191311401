/**
 * @author DCHIRUM1
 */
import {Injectable} from '@angular/core';
import {Config} from '../../config';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RfiThresholdRequestService {
	private config = new Config();

	constructor(private http: HttpClient) {}

	findAll(userId): Observable<any> {
		return this.http.get(
			environment.BACKEND_URL + 'edcmservice/rfiThreshold' + '/' + userId
		);
	}

	findThreshold(program, commodity, userId): Observable<any> {
		return this.http.get(
			environment.BACKEND_URL +
				'edcmservice/rfiThreshold/' +
				program +
				'/' +
				commodity +
				'/' +
				userId
		);
	}

	findThresholdForPsa(psa, userId): Observable<any> {
		return this.http.get(
			environment.BACKEND_URL +
				'edcmservice/rfiThreshold/psa/' +
				psa +
				'/' +
				userId
		);
	}

	getProgramsCommodities(userId): Observable<any> {
		return this.http.get(
			environment.BACKEND_URL +
				'edcmservice/rfiThreshold/programsCommodities' +
				'/' +
				userId
		);
	}

	getPSAList(userId): Observable<any> {
		return this.http.get(
			environment.BACKEND_URL +
				'edcmservice/rfiThreshold/psaList' +
				'/' +
				userId
		);
	}

	getPSAListByCMFR(userId, cmfr): Observable<any> {
		return this.http.get(
			environment.BACKEND_URL +
				'edcmservice/rfiThreshold/psaListByCmfr' +
				'/' +
				userId +
				'/' +
				cmfr
		);
	}

	saveThreshold(threshold, isEdit, userId): Observable<any> {
		return this.http.post(
			environment.BACKEND_URL +
				'edcmservice/rfiThreshold/save/' +
				isEdit +
				'/' +
				userId,
			threshold
		);
	}
}
